import { useEffect } from 'react';
import { auth } from '@90poe/auth';
import { useFeatureFlags } from '@90poe/feature-flags';
import { useStreamChatRoot } from '@90poe/oos-stream-chat';
import { Severity } from '@90poe/journal';
import { logError } from '../../../helpers/logger';

export const ERROR_MESSAGES = {
    FAILED_TO_LOAD_FEATURE_FLAGS: 'Failed to load feature flags',
    FAILED_TO_CONNECT_TO_STREAM_CHAT: 'Failed to connect to stream chat',
};

export const useStreamChatLogin = () => {
    const flags = useFeatureFlags<{ chatEnabled: boolean }>();
    const streamChatRoot = useStreamChatRoot();

    useEffect(() => {
        if (!flags.chatEnabled) {
            return;
        }

        auth.streamChat
            .login(streamChatRoot.chatClient, streamChatRoot.apolloClient)
            .catch((error: Error) => {
                logError({
                    uuid: 'b7bc30c0-c05a-47a0-acad-7bc9745ec5a7',
                    message: ERROR_MESSAGES.FAILED_TO_CONNECT_TO_STREAM_CHAT,
                    level: Severity.Error,
                    data: { error },
                });
            });
    }, [
        flags.chatEnabled,
        streamChatRoot.apolloClient,
        streamChatRoot.chatClient,
    ]);
};
