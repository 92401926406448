import type { SupportConfigItem } from '@90poe/oos-config';
import { Severity } from '@90poe/journal';
import { logError } from '../../../helpers/logger';

const formatErrorMessage = (
    index: number,
    configItem: SupportConfigItem,
    property: string,
) => {
    return `Support config item[${index}] has invalid "${property}" property or it is not defined. Provided - ${JSON.stringify(
        configItem,
    )}`;
};

export const ERROR_MESSAGES = {
    INVALID_CONFIG: 'Provided Support Config is invalid.',
};

const hasSupportConfigValidData = (supportConfig?: SupportConfigItem[]) => {
    try {
        supportConfig?.forEach((c, i) => {
            if (typeof c.path !== 'string' || !c.path) {
                throw new Error(formatErrorMessage(i + 1, c, 'path'));
            }
            if (typeof c.message !== 'string' || !c.message) {
                throw new Error(formatErrorMessage(i + 1, c, 'message'));
            }
            if (!Array.isArray(c.tenants) || c.tenants.length === 0) {
                throw new Error(formatErrorMessage(i + 1, c, 'tenants'));
            }
        });
        return true;
    } catch (error) {
        logError({
            uuid: '334e15cb-44bc-4946-a591-25800e4d8442',
            message: ERROR_MESSAGES.INVALID_CONFIG,
            level: Severity.Error,
            data: { error, supportConfig },
        });
        return false;
    }
};

export { hasSupportConfigValidData, formatErrorMessage };
