import translations from './en.json';

export const NAMESPACE = 'common';
export default {
    ns: NAMESPACE,
    resources: {
        en: {
            [NAMESPACE]: translations,
        },
    },
};
