import type { FC } from 'react';
import { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { notification } from '@90poe/antdv4';
import type { SupportConfigItem } from '@90poe/oos-config';

// We want to keep the information about shown banners always in memory, so that it persists through the whole user session.
const shownBanners: Record<string, boolean> = {};

const setBannerAsShown = (path: string) => {
    shownBanners[path] = true;
};

type BannerProps = {
    path?: string;
    message?: string;
    tenants?: string[];
};

const Banner: FC<BannerProps> = ({ path, message, tenants }) => {
    const currentTenant = location.host.split('.')[0];
    const notShownYet = path && !shownBanners[path];
    const allowedTenant = tenants?.includes(currentTenant);
    const canShowBanner = notShownYet && allowedTenant;

    // Antd v4 Notification creates a new React instance with `ReactDOM.render`,
    // so we need to call it in a useEffect, otherwise React will complain about the impurity of the component.
    // https://4x.ant.design/components/notification/#Why-I-can-not-access-context,-redux,-ConfigProvider-locale/prefixCls-in-notification
    useEffect(() => {
        if (!path || !message || !canShowBanner) {
            return;
        }

        notification.error({
            key: path,
            message: 'System Alert',
            description: message,
            duration: null,
            onClose: () => setBannerAsShown(path),
        });
        setBannerAsShown(path);
    }, [path, message, canShowBanner]);

    return null;
};

export const Routes: FC<{ supportConfig: SupportConfigItem[] }> = ({
    supportConfig,
}) => {
    return (
        <>
            {supportConfig.map(({ path, message, tenants }) => (
                <Route
                    key={path}
                    path={path}
                    render={() => (
                        <Banner
                            path={path}
                            message={message}
                            tenants={tenants}
                        />
                    )}
                />
            ))}
        </>
    );
};
