import {
    APP_STATUS_NOT_FOUND,
    APP_STATUS_NOT_ALLOWED,
    APP_STATUS_LOADING,
} from '@90poe/oos-config';
import { ErrorPage, Loading, ErrorType } from '@90poe/ui';
import { useAppStatus } from '@90poe/shared-state';

export const StatusPage = () => {
    const { appStatus } = useAppStatus();

    switch (appStatus) {
        case APP_STATUS_LOADING:
            return <Loading />;
        case APP_STATUS_NOT_FOUND:
            return <ErrorPage errorType={ErrorType.NotFound} />;
        case APP_STATUS_NOT_ALLOWED:
            return <ErrorPage errorType={ErrorType.NoPermission} />;
        default:
            break;
    }

    return null;
};
