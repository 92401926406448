import { config } from '@90poe/oos-config';
import { getClient } from '@90poe/apollo';
import { auth, handleUnauthorized } from '@90poe/auth';
import { logError } from '../../../helpers/logger';

export const client = getClient(
    config.CLIENT_API_NOTIFICATIONS_QUERIES_AND_MUTATIONS,
    {
        getServiceToken: auth.getServiceToken,
        onUnauthorized: () => void handleUnauthorized(),
        logError,
    },
);
