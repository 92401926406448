import type { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { supportConfig } from '@90poe/oos-config';
import { useFeatureFlags } from '@90poe/feature-flags';

import { hasSupportConfigValidData } from './helpers/validators';
import { Routes } from './Routes';

type FeatureFlags = {
    bannersEnabled: boolean;
};

export const BannerRouter: FC = () => {
    const { bannersEnabled } = useFeatureFlags<FeatureFlags>();

    if (!bannersEnabled) {
        return null;
    }

    const isSupportConfigEmpty = supportConfig.length === 0;
    const isSupportConfigValid =
        !isSupportConfigEmpty && hasSupportConfigValidData(supportConfig);

    if (isSupportConfigEmpty || !isSupportConfigValid) {
        return null;
    }

    return (
        <Router>
            <Routes supportConfig={supportConfig} />
        </Router>
    );
};
