import {
    type APP_KEYS,
    type AppConfigType,
    type AppConfigItemType,
} from '@90poe/oos-config';
import type { IUser } from '@90poe/shared-state';

const isAllowedApp = (appConfig: AppConfigItemType, user: IUser) => {
    return (
        user.hasPermissions(appConfig.minPermissions) &&
        (!user.isSpecialTravelUser ||
            appConfig.isAllowedForSpecialTravelUsers) &&
        (!user.isSupplierUser || appConfig.isAllowedForSupplierUsers)
    );
};

export const filterAllowedApps = (
    appsConfig: Partial<AppConfigType>,
    user: IUser,
): Partial<AppConfigType> => {
    return Object.entries(appsConfig).reduce((acc, [appKey, appConfig]) => {
        if (isAllowedApp(appConfig, user)) {
            acc[appKey] = appConfig;
        }
        return acc;
    }, {} as Partial<AppConfigType>);
};

export const filterRestrictedApps = (
    appsConfig: Partial<AppConfigType>,
    user: IUser,
): Partial<AppConfigType> => {
    return Object.entries(appsConfig).reduce((acc, [appKey, appConfig]) => {
        if (!isAllowedApp(appConfig, user)) {
            acc[appKey] = appConfig;
        }
        return acc;
    }, {} as Partial<AppConfigType>);
};

export const isProductApp = (
    appsConfig: Partial<AppConfigType>,
    appKey: APP_KEYS,
): boolean => {
    return !appsConfig[appKey]?.isLayout;
};

export const isLayoutApp = (
    appsConfig: Partial<AppConfigType>,
    appKey: APP_KEYS,
): boolean => {
    return !!appsConfig[appKey]?.isLayout;
};

export const isActiveApp = (
    prefix: string,
    location: { pathname: string },
): boolean => {
    if (prefix === '') {
        return false;
    }
    return prefix === '/'
        ? location.pathname.startsWith(prefix)
        : !!new RegExp(`^\\${prefix}($|\\/)`).exec(location.pathname);
};
