import { type AuthTokenData } from '@90poe/auth';
import type { RealtimeDataSources } from '@90poe/realtime-data';
import {
    initRealtimeDataObservers as initRealtimeData,
    getNotificationsObserver,
} from '@90poe/realtime-data';

export type RealtimeDataClientConfig = {
    notificationsEnabled: boolean;
    queriesAndMutationsUri: string;
    subscriptionsUri: string;
    serviceToken: string;
};

const getFromEnv = (key: string) => process.env[key];

export const initRealtimeDataObservers: (
    token: AuthTokenData,
    config: RealtimeDataClientConfig,
) => RealtimeDataSources = (token, config) => {
    if (!config.notificationsEnabled) {
        return undefined;
    }

    let devToolsGlobal: string | undefined;
    if (getFromEnv('NODE_ENV') !== 'production') {
        devToolsGlobal = 'notifications';
    }

    initRealtimeData({
        queriesAndMutationsUri: config.queriesAndMutationsUri,
        subscriptionsUri: config.subscriptionsUri,
        userId: token.userId,
        serviceToken: config.serviceToken,
        devToolsGlobal,
    });

    return {
        notificationsObserver: getNotificationsObserver(),
    };
};
