import { useEffect, type FC } from 'react';
import { withTranslation } from 'react-i18next';
import { SharedStoreProvider, type Store } from '@90poe/shared-state';
import { FeatureFlagsProvider } from '@90poe/feature-flags';
import { ErrorBoundary } from '@90poe/mfe-init';
import { LocaleProvider } from '@90poe/oos-i18n';
import type { StreamChatRoot } from '@90poe/oos-stream-chat';
import { StreamChatRootProvider } from '@90poe/oos-stream-chat';
import type { RealtimeDataSources } from '@90poe/realtime-data';
import { RealtimeDataProvider } from '@90poe/realtime-data';
import { ThemeProvider } from '@90poe/css-in-js';
import { APP_STATUS_NOT_ALLOWED } from '@90poe/oos-config';
import { Severity } from '@90poe/journal';

import i18nModule, { NAMESPACE } from '../locales/module';
import { Shell } from './Shell';
import { logError } from '../helpers/logger';

export type Props = {
    registerMFEs: () => void;
    sharedStore: Store;
    realtimeDataObservers: RealtimeDataSources;
    streamChatRoot?: StreamChatRoot;
};

const WrappedErrorBoundary = withTranslation('common')(ErrorBoundary);

export const App: FC<Props> = ({
    realtimeDataObservers,
    registerMFEs,
    sharedStore,
    streamChatRoot,
}) => {
    useEffect(() => {
        const checkUserIsLoggedIn = async () => {
            const user = sharedStore.user.getUser();
            if (!user) {
                logError({
                    uuid: 'a95730da-5226-4814-9780-837f43f44081',
                    message: 'User is not set in the shared store',
                    level: Severity.Error,
                });

                await sharedStore.appStatus.setAppStatus(
                    APP_STATUS_NOT_ALLOWED,
                );
            }
        };
        void checkUserIsLoggedIn();
    }, [sharedStore]);

    const user = sharedStore.user.getUser();
    if (!user) {
        return null;
    }

    return (
        <LocaleProvider
            modules={[i18nModule]}
            config={{ defaultNS: NAMESPACE }}
        >
            <WrappedErrorBoundary mfe={__mfe as string}>
                <ThemeProvider>
                    <SharedStoreProvider value={sharedStore.getStoreInstance()}>
                        <FeatureFlagsProvider enableFeatureFlags user={user}>
                            <RealtimeDataProvider value={realtimeDataObservers}>
                                <StreamChatRootProvider value={streamChatRoot}>
                                    <Shell registerMFEs={registerMFEs} />
                                </StreamChatRootProvider>
                            </RealtimeDataProvider>
                        </FeatureFlagsProvider>
                    </SharedStoreProvider>
                </ThemeProvider>
            </WrappedErrorBoundary>
        </LocaleProvider>
    );
};
