import { useMemo } from 'react';

import { menuConfigByKey } from '@90poe/oos-config';
import {
    ON_RADAR_TITLES,
    TAB_TITLE_POSTFIX,
    TAB_TITLE_SEPARATOR,
} from './constants';
import { useMenuActiveItemKey, useCustomPageTitle } from '@90poe/shared-state';

export const useCalculateProductTitle = () => {
    const { menuActiveItemKey } = useMenuActiveItemKey();
    const { customPageTitle } = useCustomPageTitle();
    return useMemo(() => {
        const activeItem = menuActiveItemKey
            ? menuConfigByKey[menuActiveItemKey]
            : null;
        const activeItemTitle = activeItem?.props.title;

        const productTitle =
            activeItemTitle && ON_RADAR_TITLES.includes(activeItemTitle)
                ? `onRADAR ${activeItemTitle}`
                : activeItemTitle ?? customPageTitle ?? '';
        const browserPageTitle = `${productTitle}${TAB_TITLE_SEPARATOR}${TAB_TITLE_POSTFIX}`;

        const payload = {
            productTitle,
            browserPageTitle,
            isBeta: activeItem?.meta?.isBeta ?? false,
        };

        return payload;
    }, [customPageTitle, menuActiveItemKey]);
};
