import type { FC } from 'react';
import { css, useTheme } from '@90poe/css-in-js';
import { Layout } from '@90poe/antdv4';
import { Severity } from '@90poe/journal';
import { useMenuIsCollapsed } from '@90poe/shared-state';

import { logError } from '../../../../helpers/logger';

export const ERROR_MESSAGES = {
    UPDATE_MENU_VISIBILITY_ERROR: 'Failed to update menu visibility',
};

const { Sider: AntdSider } = Layout;

type Props = {
    isVisible: boolean;
};

export const Sider: FC<Props> = ({ isVisible }) => {
    const theme = useTheme();
    const { menuIsCollapsed, setMenuIsCollapsed } = useMenuIsCollapsed();

    const width = isVisible ? theme.WebMetrics.DEPRECATED_menuMetrics.width : 0;
    const collapsedWidth = isVisible
        ? theme.WebMetrics.DEPRECATED_menuMetrics.collapsedWidth
        : 0;

    const handleCollapse = (collapsed: boolean) => {
        setMenuIsCollapsed(collapsed).catch((reason: unknown) => {
            logError({
                uuid: 'a084446f-2db7-47ef-8b62-fbc401c7800d',
                message: ERROR_MESSAGES.UPDATE_MENU_VISIBILITY_ERROR,
                level: Severity.Error,
                data: { reason },
            });
        });
    };

    return (
        <AntdSider
            id="nav"
            breakpoint="lg"
            width={width}
            collapsedWidth={collapsedWidth}
            collapsible
            collapsed={menuIsCollapsed}
            trigger={null}
            onCollapse={handleCollapse}
            css={css`
                z-index: 3;
            `}
            data-testid="sider"
        />
    );
};
