import type { AppConfigType, AppConfigItemType } from '@90poe/oos-config';
import { appsConfig } from '@90poe/oos-config';
import { Severity } from '@90poe/journal';
import type { IUser } from '@90poe/shared-state';

import { logError } from '../helpers/logger';
import * as singleSpaWrapper from './singleSpaWrapper';
import { type SharedServices } from '../types';
import { filterAllowedApps } from '../helpers/appsUtils';

export const ERROR_MESSAGES = {
    NO_APP_CONFIG: 'Unexpected undefined value for `appConfig`.',
};

export function registerApps(
    user: IUser,
    sharedServices: SharedServices,
): void {
    const allowedApps = filterAllowedApps(appsConfig, user);

    Object.entries(allowedApps).forEach(([appKey, appConfig]) => {
        registerApp(allowedApps, appConfig, appKey, sharedServices);
    });

    singleSpaWrapper.initRoutingEventListeners(
        user,
        sharedServices.sharedStore,
    );
    singleSpaWrapper.addErrorHandler();
    singleSpaWrapper.start();
}

function registerApp(
    allowedApps: Partial<AppConfigType>,
    appConfig: AppConfigItemType,
    appKey: string,
    sharedServices: SharedServices,
): void {
    if (!appConfig) {
        logError({
            uuid: '6a7139bb-4d91-49e7-add0-3f83ec437c0f',
            message: ERROR_MESSAGES.NO_APP_CONFIG,
            level: Severity.Warning,
            data: { allowedApps, appKey, appConfig },
        });
        return;
    }

    if (!appConfig.pathPrefix) {
        return;
    }

    const { sharedStore, ...otherSharedServices } = sharedServices;
    const customProps = {
        baseUrl: appConfig.pathPrefix,
        rootClient: sharedStore.getStoreInstance(),
        sharedStore,
        ...otherSharedServices,
    };

    singleSpaWrapper.registerApplication(appKey, appConfig, customProps);
}
