import { type AuthTokenData } from '@90poe/auth';
import { Severity } from '@90poe/journal';
import * as onboarding from '@90poe/onboarding';
import { config } from '@90poe/oos-config';
import { getSubdomain } from '../../helpers/getSubdomain';
import { logError } from '../../helpers/logger';

const identifyOnboarding: (
    userIdentity: AuthTokenData,
) => Promise<void> = async ({
    email,
    name,
    userId,
    account,
    userExtraType,
}) => {
    const tenantSubdomain = getSubdomain();

    await onboarding.identify(userId, {
        name,
        email,
        accountId: account,
        tenantSubdomain,
        accountExtraType: userExtraType,
    });
};

const initialiseOnboarding = (apiToken: string): void => {
    if (apiToken) {
        onboarding.initialise(apiToken);
    }
};

export const initOnboarding: (token: AuthTokenData) => Promise<void> = async (
    token,
) => {
    try {
        if (!config.ONBOARDING_USERFLOW_TOKEN) {
            return;
        }

        initialiseOnboarding(config.ONBOARDING_USERFLOW_TOKEN);
        await identifyOnboarding(token);
    } catch (error) {
        logError({
            uuid: 'bf5a0991-3cfb-4f76-97cc-8a2c84561e06',
            message: 'Error while trying to initialize onboarding.',
            data: { error },
            level: Severity.Error,
        });
    }
};
