import { useEffect } from 'react';
import type {
    ParsedNotificationData,
    Notification,
} from '@90poe/realtime-data';
import { useRealtimeData } from '@90poe/realtime-data';
import { notification as antdNotification } from '@90poe/antdv4';
import { Severity } from '@90poe/journal';

import { client as notificationsClient } from '../apollo/client';
import { MarkNotificationAsRead } from '../apollo/mutations';
import { logError, logMessage } from '../../../helpers/logger';

export const ERROR_MESSAGES = {
    UNEXPECTED_TERMINATION:
        'Unexpected termination of notifications observer due to error.',
    UNEXPECTED_COMPLETION:
        'Notifications observer completed successfully, but the observer is not meant to complete.',
    UNABLE_TO_DISPLAY_POPUP: 'Unable to display notification pop-up.',
    FAILED_TO_MARK_AS_READ:
        "Failed to mark notification as read when clicking on Ant Design's slide-in notification.",
    FAILED_TO_REDIRECT: 'Failed to redirect user to notification link.',
};

// TODO: The whole logic in here does not work as of the moment because of https://ninetypercent.atlassian.net/browse/PFE-947
export const useNotificationsAlerts = () => {
    const realtimeDataObservers = useRealtimeData();

    const handleOnClick = async (notification: Notification) => {
        const data = JSON.parse(notification.data) as ParsedNotificationData;

        try {
            await notificationsClient.mutate({
                mutation: MarkNotificationAsRead,
                variables: {
                    notificationId: notification.id,
                },
            });
        } catch (error) {
            logError({
                uuid: 'f9f15885-ea3b-411a-93bb-3fef630b67a7',
                message: ERROR_MESSAGES.FAILED_TO_MARK_AS_READ,
                level: Severity.Error,
                data: { error },
            });
        }

        if (data.link) {
            try {
                window.history.pushState(null, '', data.link);
            } catch (error) {
                logError({
                    uuid: 'bbc8aeef-456c-48ce-808a-776459868f2c',
                    message: ERROR_MESSAGES.FAILED_TO_REDIRECT,
                    level: Severity.Error,
                    data: { error },
                });
            }
        }
    };

    useEffect(() => {
        if (!realtimeDataObservers?.notificationsObserver) {
            return;
        }

        const subscription =
            realtimeDataObservers.notificationsObserver.subscribe({
                next(notification) {
                    /**
                     *  When an unread notification is received, display a
                     *  notification popup.
                     */

                    if (notification.isRead) {
                        return;
                    }
                    try {
                        const data = JSON.parse(
                            notification.data,
                        ) as unknown as ParsedNotificationData;

                        antdNotification[data.priority]({
                            message: data.title,
                            description: data.body,
                            duration: data.duration,
                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                            onClick: () => handleOnClick(notification),
                        });
                    } catch (error) {
                        logError({
                            uuid: '52ecf99a-8f6b-4884-9036-27317a179a3d',
                            message: ERROR_MESSAGES.UNABLE_TO_DISPLAY_POPUP,
                            level: Severity.Error,
                            data: { error },
                        });
                    }
                },
                error(error) {
                    logError({
                        uuid: '0332bcf2-da3a-4f63-9d08-3663b4bb2897',
                        message: ERROR_MESSAGES.UNEXPECTED_TERMINATION,
                        level: Severity.Error,
                        data: { error: error as unknown },
                    });
                },
                complete() {
                    logMessage({
                        uuid: '366aefdf-c84a-4da1-9055-9db9d41e14cc',
                        message: ERROR_MESSAGES.UNEXPECTED_COMPLETION,
                        level: Severity.Error,
                    });
                },
            });

        return (): void => {
            subscription.unsubscribe();
        };
    }, [realtimeDataObservers?.notificationsObserver]);
};
