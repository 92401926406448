import { useEffect } from 'react';
import { token } from '@90poe/auth';
import { initOnboarding } from '../../../sharedServices/onboarding';

export const useOnboarding = () => {
    const apiToken = token.getAuthTokenData();

    useEffect(() => {
        void initOnboarding(apiToken);
    }, [apiToken]);
};
