import * as singleSpa from '@90poe/single-spa';
import type { PlatformProps } from '@90poe/single-spa';
import {
    type AppConfigItemType,
    APP_STATUS_LOADING,
    APP_STATUS_LOADED,
} from '@90poe/oos-config';
import { Severity } from '@90poe/journal';
import { logError } from '../../helpers/logger';
import { isActiveApp } from '../../helpers/appsUtils';

// TODO: Should this all be moved into `@90poe/single-spa`?

export const ERROR_MESSAGES = {
    FAILED_TO_REGISTER_APP: 'Failed to register app in single-spa.',
    FAILED_TO_IMPORT_APP: 'Failed to import app into single-spa.',
    GENERIC_SINGLE_SPA_ERROR: 'Error caught by single-spa.',
};

export const registerApplication = (
    /**
     * The name of the application. This name is used to uniquely identify the application.
     */
    appName: string,
    /**
     * The MFE conifuguration.
     */
    appConfig: AppConfigItemType,
    /**
     * The custom props that will be passed to the application by single-spa
     */
    customProps: PlatformProps,
): void => {
    /**
     * The function that returns a promise that resolves with a single-spa application.
     * @param config The configuration object that will be passed to the application.
     * @returns A promise that resolves with a single-spa application.
     * @see https://single-spa.js.org/docs/api#registerapplication
     *  - Note that the link is for a higher version of single-spa,
     *      so until we migrate it might be a bit different from what is actually implemented here
     */
    const loadApplication = async () => {
        if (!appConfig.isLayout) {
            customProps.sharedStore.appStatus
                .setAppStatus(APP_STATUS_LOADING)
                .catch((reason: unknown) => {
                    logError({
                        uuid: '6c08c118-9853-4569-8394-3a4752617936',
                        message: ERROR_MESSAGES.FAILED_TO_REGISTER_APP,
                        level: Severity.Error,
                        data: { reason },
                    });
                });
        }

        return System.import(appConfig.bundleUrl).finally(() => {
            if (!appConfig.isLayout) {
                customProps.sharedStore.appStatus
                    .setAppStatus(APP_STATUS_LOADED)
                    .catch((reason: unknown) => {
                        logError({
                            uuid: '5af3a40c-fd17-4c57-bc74-c8328a692c32',
                            message: ERROR_MESSAGES.FAILED_TO_IMPORT_APP,
                            level: Severity.Error,
                            data: { reason },
                        });
                    });
            }
        });
    };

    /**
     * The function that determines whether the application is active or not.
     * @param location The current location.
     * @returns True if the application is active, false otherwise.
     * @see https://single-spa.js.org/docs/api#lifecycle-activity-functions
     *  - Note that the link is for a higher version of single-spa,
     *      so until we migrate it might be a bit different from what is actually implemented here
     */
    const activeWhen = (location: Location): boolean =>
        isActiveApp(appConfig.pathPrefix, location);

    singleSpa.registerApplication({
        name: appName,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - // TODO: We are returning a Sysetm.import() promise and it's hard to type it to match single-spa requirements
        app: loadApplication,
        activeWhen,
        customProps,
    });
};

export const addErrorHandler = (): void => {
    singleSpa.addErrorHandler((error: unknown) => {
        logError({
            uuid: '9e182c18-78af-4d79-b75d-804d90433c26',
            message: ERROR_MESSAGES.GENERIC_SINGLE_SPA_ERROR,
            level: Severity.Error,
            data: { error },
        });
    });
};

export const start = (): void => {
    singleSpa.start();
};
