import type {
    AuthJournalInstance,
    AuthorizeReturn,
    AuthTokenData,
} from '@90poe/auth';
import { auth, token } from '@90poe/auth';
import { AppError, Severity } from '@90poe/journal';
import { logError, journalInstance } from '../../helpers/logger';

export const authorizeUserAndGetToken =
    async (): Promise<AuthTokenData | null> => {
        let authorizationResult: AuthorizeReturn | undefined;
        try {
            auth.setupAuthLoggers(
                journalInstance as AuthJournalInstance,
                AppError,
            );
            authorizationResult = await auth.authorize();
        } catch (error) {
            logError({
                uuid: 'd5e123e0-0532-4a06-8c62-7a22e747a3b6',
                message: 'Error while trying to authorize user.',
                data: { error },
                level: Severity.Error,
            });
            auth.login();
            return null;
        }

        if (authorizationResult.idTokenData?.error) {
            auth.login();
            return null;
        }

        if (authorizationResult.serviceTokenData?.error) {
            auth.login({
                errorCode: authorizationResult.serviceTokenData.error.code,
            });
            return null;
        }

        const serviceToken = authorizationResult.serviceTokenData?.serviceToken;

        if (!serviceToken) {
            logError({
                uuid: 'a0bbb9b4-c311-4300-9900-80ccae739550',
                message: 'Expected service token to be defined',
                level: Severity.Error,
            });
            auth.login();
            return null;
        }
        auth.startRefreshPolling(serviceToken).catch((reason: unknown) => {
            logError({
                uuid: '9887ac9f-1cce-4ee8-9d4f-c68943356d82',
                message: 'Error while polling for new service token.',
                data: { error: reason },
                level: Severity.Error,
            });
            auth.login();
        });

        try {
            return token.getAuthTokenData();
        } catch (error) {
            // error logged within getAuthTokenData

            auth.login();
            return null;
        }
    };
