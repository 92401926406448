import { Helmet } from 'react-helmet';
import type { FC } from 'react';
import { useEffect } from 'react';
import { styled } from '@90poe/css-in-js';
import { Layout as AntdLayout, Grid } from '@90poe/antdv4';
import { useProductTitle, useUser } from '@90poe/shared-state';
import { StatusPage } from '../StatusPage';
import { Sider } from './components/Sider';
import { BannerRouter } from '../BannerRouter';
import { useNotificationsAlerts } from '../Notifications/hooks/useNotificationsAlerts';
import { useStreamChatLogin } from '../StreamChat/hooks/useStreamChatLogin';
import { useOnboarding } from '../Onboarding/hooks/useOnboarding';
import { useCalculateProductTitle } from '../../sharedServices/useCalculateProductTitle';

const { Header: AntdHeader, Content: AntdContent } = AntdLayout;

const Header = styled(AntdHeader)`
    height: auto;
    z-index: ${({ theme }) => theme.WebMetrics.zIndexes.BASE};
`;

const Content = styled(AntdContent)`
    min-height: ${({ theme }) =>
        theme.WebMetrics.DEPRECATED_containerMetrics.minHeight};
    padding-top: ${({ theme }) =>
        theme.WebMetrics.DEPRECATED_headerMetrics.containerHeight};
`;

const Layout = styled(AntdLayout)(`
        min-height: 100vh;
`);

const App = styled.div`
    opacity: 0;
    transition: opacity 1s ease-out;
    &.application-mounted {
        height: 100%;
        opacity: 1;
    }
`;

type Props = {
    registerMFEs: () => void;
};

export const Shell: FC<Props> = ({ registerMFEs }) => {
    const productTitle = useCalculateProductTitle();
    const { setProductTitle, resetProductTitle } = useProductTitle();
    const screens = Grid.useBreakpoint();
    const { user } = useUser();
    // TODO: The notification subscription doesn't work as of the moment because of https://ninetypercent.atlassian.net/browse/PFE-947
    useNotificationsAlerts();
    useStreamChatLogin();
    useOnboarding();

    useEffect(() => {
        void setProductTitle(productTitle);
        return () => {
            void resetProductTitle();
        };
    }, [resetProductTitle, setProductTitle, productTitle]);

    useEffect(() => {
        registerMFEs();
    }, [registerMFEs]);

    const canViewSider = !user?.isSpecialTravelUser && !user?.isSupplierUser;

    return (
        <>
            <Helmet>
                <title>{productTitle.browserPageTitle}</title>
            </Helmet>
            <BannerRouter />
            <Layout>
                {canViewSider && <Sider isVisible={!!screens.sm} />}
                <Layout>
                    <Header id="header" />
                    <Content>
                        <StatusPage />
                        <App id="app" />
                        {/* container for AWS quicksight iframe used by Reporting MFE */}
                        <div id="dashboard-container" />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};
